import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as data from './data'
import * as styles from './styles'

import Alert from '../../components/Alert'
import Table from '../../components/Table'
import HTable from '../../components/HeaderTable'
import FormVehicle from '../../components/FormVehicle'
import api from '../../services/api'

import { routes } from '../../routes/data'
import { validators } from '../../utils/validators'

const Vehicle = () => {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [menu, setMenu] = React.useState('')
  const [vehicleData, setVehicleData] = React.useState([])
  const [formData, setFormData] = React.useState(data.defaultValuedataForm)
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [uuid, setUuid] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue
    })
  }

  const validateForm = (form, value) => {
    let temp = false
    for (let i = 0; i < form.length; i++) {
      temp = validators(form[i].type, value[form[i].value]).err
      if (temp === true) break
    }
    return temp
  }

  const handleSubmit = (type) => {
    setIsSubmit(true)
    setError(false)
    setMsg('')
    if (validateForm(data.dataForm, formData) === false) {
      setLoading(true)
      setOpen(true)

      const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

      if (!authData) {
        return
      }

      const config = {
        headers: {
          Authentication: authData?.token || ''
        }
      }

      if (type === 'new') {
        api.post('/vehicle/', {
          model: formData.model,
          license_plate: formData.license_plate.replace('-', ''),
          category: formData.category
        }, config).then(() => {
          setLoading(false)
        }).catch((err) => {
          if (err?.response?.data?.detail[0]?.msg) {
            setMsg(err.response.data.detail[0].msg)           
          }
          setError(true)
          setLoading(false)
        })
      } else if (type === 'edit') {
        api.put(`/vehicle/${formData.uuid}`, {
          model: formData.model,
          license_plate: formData.license_plate.replace('-', ''),
          category: formData.category
        }, config).then(() => {
          setLoading(false)
        }).catch(() => {
          setError(true)
          setLoading(false)
        })
      }
    }
  }

  const handleDelete = () => {
    setIsDelete(false)
    setError(false)
    setLoading(true)

    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(routes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.delete(`/vehicle/${uuid}`, config).then(() => {
      setLoading(false)
    }).catch(() => {
      setError(true)
      setLoading(false)
    })
  }

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      return
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.get('/vehicle/all/', config).then((resp) => {
      setVehicleData(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <Container sx={styles.Container}>
      { menu === 'new'
        ? <FormVehicle
            data={data.dataForm}
            value={formData}
            isSubmit={isSubmit}
            onChange={handleChangeForm}
            handleSubmit={() => handleSubmit('new')}
            loading={loading}
            text='Cadastro de Veículo'
            type='form'
            cancel={() => {
              setIsSubmit(false)
              setMenu('')
            }}
          />
        : menu === 'edit'
          ? <FormVehicle
              data={data.dataFormEdit}
              value={formData}
              isSubmit={isSubmit}
              onChange={handleChangeForm}
              handleSubmit={() => handleSubmit('edit')}
              loading={loading}
              text='Editor de Cadastro de Veículo'
              type='form'
              cancel={() => {
                setIsSubmit(false)
                setMenu('')
              }}
            />
          : menu === 'show'
            ? <FormVehicle
                data={data.dataFormShow}
                value={formData}
                text='Veículo'
                type='perfil'
                cancel={() => {
                  setIsSubmit(false)
                  setMenu('')
                }}
              />
            : <>
          <HTable text="Veículos" onClick={() => {
            setFormData(data.defaultValuedataForm)
            setMenu('new')
          }} />
          <CssBaseline />
          <Table
            rows={vehicleData}
            columns={data.columns}
            show={(id) => {
              setFormData(vehicleData.filter(element => element.uuid === id)[0])
              setMenu('show')
            }}
            edit={(id) => {
              setFormData(vehicleData.filter(element => element.uuid === id)[0])
              setMenu('edit')
            }}
            delete={(id) => {
              setIsDelete(true)
              setOpen(true)
              setUuid(id)
            }}
          />
        </>
       }
       <Alert
        open={open}
        loading={loading}
        error={error}
        msg={msg}
        handleModalClose={() => setOpen(false)}
        handleClose={() => document.location.reload(true)}
        delete={isDelete}
        handleDelete={handleDelete}
       />
    </Container>
  )
}

export default Vehicle
