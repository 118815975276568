export const Container = {
  display: 'flex',
  flexDirection: 'column',
}

export const Box = {
  display: 'flex',
  // alignItens: 'center',
  justifyContent: 'center',
  marginBottom: '30px'
}

export const BoxPhoto = {
  backgroundColor: '#bbbbbb',
  display: 'flex',
  alignItens: 'center',
  justifyContent: 'center',
  width: '400px', 
  height: '225px' 
}
