export const Container = {
  display: 'flex',
  flexDirection: 'column'
}

export const BoxActions = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}

export const BoxIcon = {
  bgcolor: '#1769aa',
  borderRadius: '5px',
  '&:hover': {
    cursor: 'pointer'
  }
}

export const TextHeader = {
  textTransform: 'uppercase',
  fontSize: 18,
  fontWeight: 'bold',
  color: '#505050'
}

export const TextCell = {
  fontFamily: 'Poppins',
  fontWeight: 1000,
  fontSize: 17,
  color: 'gray'
}
