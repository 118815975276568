import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { uniqueId } from 'lodash'
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  // useMap
} from 'react-leaflet'

import * as styles from './styles'

import * as Icon from '../../components/icon'
import { routes as apiRoutes } from '../../routes/data'
import api from '../../services/api'
import { colorsRoute } from '../../utils/colors'

const Map = () => {
  const navigate = useNavigate()
  const center = [-16.072870129464917, -57.68599658169415]

  const [checkedRoutes, setCheckedRoutes] = React.useState(true)
  const [checkedHouses, setCheckedHouses] = React.useState(true)
  const [checkedVigilant, setCheckedVigilant] = React.useState(true)
  const [plans, setPlans] = React.useState([])
  const [calls, setCalls] = React.useState([])
  const [filter, setFilter] = React.useState([])
  const [led, setLed] = React.useState(false)
  const [routes, setRoutes] = React.useState([])
  const [users, setUsers] = React.useState([])
  const [vigilant, setVigilant] = React.useState([])
  const [socketUrl, setSocketUrl] = React.useState(null)

  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (_closeEvent) => true
  })

  const colors = [
    Icon.blueIcon,
    Icon.redIcon,
    Icon.cyanIcon,
    Icon.deepOrangeIcon,
    Icon.greenIcon,
    Icon.yellowIcon,
    Icon.purpleIcon
  ]

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  // const ChangeView = () => {
  //   const map = useMap()
  //   map.setView(center, 14)
  //   return null
  // }

  const filterColor = (index) => {
    if (index < colorsRoute.length) {
      return colorsRoute[index]
    } else {
      return colorsRoute[index % colorsRoute.length]
    }
  }

  const filterIcon = (uuid) => {
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].uuid === uuid) {
        if (i < colors.length) {
          return colors[i]
        } else {
          return colors[i % colors.length]
        }
      }
    }
  }

  const loadData = async () => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(apiRoutes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    await api.get('/plan/all/', config).then((resp) => {
      setPlans(resp.data)
    }).catch((err) => {
      console.log(err)
    })

    await api.get('/user/all/', config).then((resp) => {
      setUsers(resp.data)
    }).catch((err) => {
      console.log(err)
    })


    await api.get('/route/all/', config).then((resp) => {
      setRoutes(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const generateLinkWeb = (uuid) => {
    const aux = process.env.REACT_APP_API_URL.replace('http', 'ws')
    return `${aux}/websocket/vigilant/${uuid}`
  }

  const loadCalls = async () => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(apiRoutes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    await api.get('/call/all/', config).then((resp) => {
      setCalls(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const verifyCall = (uuid) => {
    const res = filter.find((element) => element.uuid === uuid)
    if (res) {
      return true
    } else {
      return false
    }
  }

  React.useEffect(() => {
    const filteredCalls = []
    
    for (let i = 0; i < calls.length; i++) {    
      const aux = users.find((element) => 
        element.uuid === calls[i].user_uuid)
      
      if (aux) {
        if (calls[i].user_concluded === false || calls[i].vigilant_concluded === false) {
          if (calls[i].status !== 'Chamada cancelada') {
            filteredCalls.push(aux)
          }
        }
      }
    }

    setFilter(filteredCalls)
  }, [calls, users])

  React.useEffect(() => {
    loadData()

    const timer = setInterval(() => loadCalls(), 5000)
    return () => clearInterval(timer)
  }, [])

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))
    setSocketUrl(generateLinkWeb(user.user))
  }, [])

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setLed(true)
  //     setTimeout(() => setLed(false), 1000)
  //   }, 2000)
  //   return () => clearInterval(timer)
  // }, [])

  React.useEffect(() => {
    if (lastMessage !== null) {
      const str = lastMessage.data.split(': ')
      const aux = JSON.parse(str[1])

      const tempPos = []
      let find = false
      for (let i = 0; i < vigilant.length; i++) {
        if (vigilant[i].uuid === aux.uuid) {
          find = true
          tempPos.push(aux)
        } else {
          tempPos.push(vigilant[i])
        }
      }

      if (find === false) {
        tempPos.push(aux)
      }

      setVigilant(tempPos)
    }
  }, [lastMessage])

  return (
    <Container sx={styles.Container}>
      {console.log(connectionStatus)}
      <CssBaseline />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={styles.Text}>Mapa</Typography>
        <Stack direction="row" spacing={15}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedRoutes}
                onChange={() => setCheckedRoutes(!checkedRoutes)}
              />
            }
            label="Rotas"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedHouses}
                onChange={() => setCheckedHouses(!checkedHouses)}
              />
            }
            label="Casas"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedVigilant}
                onChange={() => setCheckedVigilant(!checkedVigilant)}
              />
            }
            label="Vigilantes"
          />
        </Stack>
      </Box>
      <Box sx={ styles.Box }>
        <MapContainer
          center={center}
          zoom={14}
          zoomControl={true}
          style={{ width: '100%', height: '100%' }}
        >
          {/* <ChangeView center={center} zoom={14} /> */}
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {checkedRoutes && routes.length > 0 && routes.map((element, index) =>
            <Polygon
              key={uniqueId()}
              pathOptions={{ color: filterColor(index) }}
              positions={JSON.parse(element.coordinates)}
            />
          )}
          {checkedHouses && users.length > 0 && users.map(element => {
            if (element.plan_uuid && element.latitude && element.longitude) {
              if (verifyCall(element.uuid) === false || led === true) {
                return (
                  <Marker
                    key={element.uuid}
                    position={[element.latitude, element.longitude]}
                    icon={filterIcon(element.plan_uuid)}
                  />
                )
              }
            } else {
              return (<></>)
            }
          })}
          {checkedVigilant && vigilant.map((element) => (
            <Marker key={uniqueId()} position={element.coords} icon={Icon.motoIcon} />
          ))}
        </MapContainer>
      </Box>
    </Container>
  )
}

export default Map
