export const colorsRoute = [
  'blue',
  'purple',
  'red',
  'teal',
  'green',
  'lightGreen',
  'deepOrange',
]

export const colorsPlan = [
  'red',
  'blue',
  'yellow',
  'purple',
  'deepOrange',
  'green',
  'cyan'
]
