import { uniqueId } from 'lodash'

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Email',
    value: 'email',
    type: 'required',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    label: 'Password',
    value: 'password',
    type: 'required',
    xs: 12,
    md: 12
  }
]

export const defaultValuedataForm = {
  email: '',
  password: ''
}
