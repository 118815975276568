import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'

import React from "react"

import * as styles from './styles'

import Camera from '../../assets/camera.svg'
import Alert from '../../components/Alert'
import HTable from '../../components/HeaderTable'
import api from '../../services/api'

const Offers = () => {
  const [data, setData] = React.useState([])
  const [menu, setMenu] = React.useState('list')
  const [photo, setPhoto] = React.useState(null)

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [uuid, setUuid] = React.useState(false)

  const generateLink = (link) => {
    return `${process.env.REACT_APP_API_URL}/offer/${link}`
  }

  const handleSubmit = async () => {
    setError(false)
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (authData) {
      setLoading(true)
      setOpen(true)
      const formData = new FormData()
      const config = {
        headers: {
          Authentication: authData.token,
          "Content-Type": "multipart/form-data"
        }
      }

      formData.append("file", photo)
      await api.post('/offer/', formData, config).then(() => {
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setError(true)
        setLoading(false)
      })
    }
  }

  const handleDelete = async () => {
    setIsDelete(false)
    setError(false)
    setLoading(true)

    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (authData) {
      const config = {
        headers: {
          Authentication: authData.token,
        }
      }

      await api.delete(`/offer/delete/${uuid}`, config).then(() => {
        setLoading(false)
      }).catch(() => {
        setError(true)
        setLoading(false)
      })
    }
  }

  const loadData = async () => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (authData) {
      const config = {
        headers: {
          Authentication: authData.token,
        }
      }

      await api.get('/offer/all/', config).then((resp) => {
        setData(resp.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <Container maxWidth="none" sx={styles.Container}>
      <CssBaseline />
      { menu === 'new' ? (
        <>
          <Box sx={styles.Box}>
            <Box sx={styles.BoxPhoto}>
              <InputLabel>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  capture="user"
                  onChange={(e) => setPhoto(e.target.files[0])}
                />
                <Box
                  component="img"
                  src={ (photo && URL.createObjectURL(photo)) || Camera }
                  sx={{ width: photo ? '400px' : '225px', height: '225px' }}
                />
              </InputLabel>
            </Box>
          </Box>
          <Stack 
            direction="row" 
            spacing={10} 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px'
            }}
          >
            <Button 
              variant='contained' 
              size="large" 
              sx={{ width: '8rem' }}
              onClick={() => setMenu('list')}
            >
              Cancelar
            </Button>
            <Button 
              variant='contained' 
              size="large" 
              sx={{ width: '8rem' }}
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </Stack>
        </>
      ) :
        <>
          <HTable text="Ofertas" onClick={() => setMenu('new')} />
          <Grid container spacing={2} sx={styles.Box}>
            {data.map((element) => (
              <Grid key={element.uuid} item md={6} xl={4}>
                <Box 
                  component="img" 
                  alt="image" 
                  src={generateLink(element.uuid)} 
                  sx={{ height: '225px', width: '400px' }}
                  onClick={() => {
                    console.log('aqui')
                    setIsDelete(true)
                    setOpen(true)
                    setUuid(element.uuid)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </> 
      }
      <Alert
        open={open}
        loading={loading}
        error={error}
        handleModalClose={() => setOpen(false)}
        handleClose={() => document.location.reload(true)}
        delete={isDelete}
        handleDelete={handleDelete}
      />
    </Container>
  )
}

export default Offers
