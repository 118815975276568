import L from 'leaflet'

import markerIconBlack from '../../assets/map-marker-black.svg'
import markerIconBlue from '../../assets/map-marker-blue.svg'
import markerIconCyan from '../../assets/map-marker-cyan.svg'
import markerIconDeepOrange from '../../assets/map-marker-deepOrange.svg'
import markerIconGreen from '../../assets/map-marker-green.svg'
import markerIconPurple from '../../assets/map-marker-purple.svg'
import markerIconRed from '../../assets/map-marker-red.svg'
import markerIconYellow from '../../assets/map-marker-yellow.svg'
import motoIconBlack from '../../assets/moto.svg'

const icon = logo =>
  new L.Icon({
    iconUrl: logo,
    iconRetinaUrl: logo,
    iconSize: new L.Point(24, 24)
  })

export const blackIcon = icon(markerIconBlack)
export const blueIcon = icon(markerIconBlue)
export const cyanIcon = icon(markerIconCyan)
export const deepOrangeIcon = icon(markerIconDeepOrange)
export const greenIcon = icon(markerIconGreen)
export const purpleIcon = icon(markerIconPurple)
export const redIcon = icon(markerIconRed)
export const yellowIcon = icon(markerIconYellow)
export const motoIcon = icon(motoIconBlack)
