import { uniqueId } from 'lodash'

export const columns = [
  {
    label: 'uuid',
    name: 'uuid',
    width: '320',
    hide: true
  },
  {
    label: 'name',
    name: 'nome',
    width: '200',
    hide: false,
    mask: 'string'
  },
  {
    label: 'cpf',
    name: 'cpf',
    width: '130',
    hide: false,
    mask: 'cpf'
  },
  {
    label: 'document',
    name: 'rg',
    width: '130',
    hide: true
  },
  {
    label: 'phone_number',
    name: 'telefone',
    width: '160',
    hide: false,
    mask: 'phone'
  },
  {
    label: 'license',
    name: 'cnh',
    width: '160',
    hide: false
  }
]

export const defaultValuedataForm = {
  email: '',
  password: '',
  role_uuid: '',
  name: '',
  cpf: '',
  document: '',
  phone_number: '',
  license: '',
  vehicle_uuid: '',
  model: '',
  license_plate: '',
  category: ''
}

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Email',
    value: 'email',
    type: 'required',
    xs: 6,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Password',
    value: 'password',
    type: 'required',
    xs: 6,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    xs: 12,
    md: 8
  },
  {
    id: uniqueId(),
    label: 'CPF',
    value: 'cpf',
    type: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'RG',
    value: 'document',
    type: 'required',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Telefone',
    value: 'phone_number',
    type: 'phone',
    mask: 'phone',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'CNH',
    value: 'license',
    type: 'required',
    xs: 12,
    md: 4
  }
]

export const dataFormEdit = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    xs: 12,
    md: 8
  },
  {
    id: uniqueId(),
    label: 'CPF',
    value: 'cpf',
    type: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'RG',
    value: 'document',
    type: 'required',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Telefone',
    value: 'phone_number',
    type: 'phone',
    mask: 'phone',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'CNH',
    value: 'license',
    type: 'required',
    xs: 6,
    md: 4
  }
]

export const dataFormShow = [
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Nome',
    value: 'name',
    mask: 'string',
    xs: 12,
    md: 8
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'CPF',
    value: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'RG',
    value: 'document',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Telefone',
    value: 'phone_number',
    mask: 'phone',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'CNH',
    value: 'license',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Categoria',
    value: 'category',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Modelo',
    value: 'model',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Placa',
    value: 'license_plate',
    mask: 'placa',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Rota',
    value: 'route',
    xs: 6,
    md: 4
  }
]

export const dataVehicleForm = [
  {
    id: uniqueId(),
    label: 'Modelo',
    value: 'model',
    type: 'required',
    xs: 12,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Placa',
    value: 'license_plate',
    type: 'required',
    mask: 'placa',
    xs: 12,
    md: 6
  }
]

export const defaultDataVehicle = {
  model: '',
  license_plate: '',
  category: ''
}
