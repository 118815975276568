import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

const HeaderTable = (props) => (
  <Box sx={styles.Box}>
    <Typography variant="h5">{props.text}</Typography>
    <Button
      size="large"
      type="submit"
      variant="contained"
      onClick={props.onClick}
    >
      Novo
    </Button>
  </Box>
)

HeaderTable.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default HeaderTable
