export const Container = {
  display: 'flex',
  flexDirection: 'column'
}

export const Text = {
  marginBottom: '10px'
}

export const BoxLeft = {
  width: '20%',
  height: '70vh'
}

export const BoxRight = {
  width: '78%',
  height: '70vh'
}

export const Box = {
  width: '100%',
  height: '70vh'
}

export const BoxMenu = {
  display: 'flex',
  justifyContent: 'space-between'
}

export const Stack = {
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'flex-end'
}

export const ActivedListButton = {
  bgcolor: '#1769aa',
  borderRadius: '5px',
  width: '100%',

  '&:hover': {
    bgcolor: '#1769aa',
  }
}

export const ListButton = {
  bgcolor: '#1976d2',
  borderRadius: '5px',
  width: '100%',

  '&:hover': {
    bgcolor: '#505050',
  }
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '300px',
  width: '400px',
  padding: '50px'
}

export const BoxButton = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly'
}

export const Button = {
  width: '7rem'
}

export const Header = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px'
}
