import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

import { mask } from '../../utils/mask'

const Perfil = (props) => {
  return (
    <Grid container spacing={2} sx={styles.Box}>
      {props.data.map((element) => (
        <Grid key={element.id} item xs={element.xs} md={element.md}>
          <Typography variant={element.variant}>{element.label}:</Typography>
          <Typography
            variant={element.variant}
            sx={styles.Text}
          >
            {mask(element.mask, props.value[element.value])}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

Perfil.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  mask: PropTypes.string
}

Perfil.defaultProps = {
  mask: ''
}

export default Perfil
