import { uniqueId } from 'lodash'

export const columns = [
  {
    label: 'uuid',
    name: 'uuid',
    width: '320',
    hide: true
  },
  {
    label: 'name',
    name: 'nome',
    width: '200',
    hide: false
  },
  {
    label: 'cpf',
    name: 'cpf',
    width: '130',
    hide: false,
    mask: 'cpf'
  },
  {
    label: 'document',
    name: 'rg',
    width: '130',
    hide: true
  },
  {
    label: 'phone_number',
    name: 'telefone',
    width: '160',
    hide: false,
    mask: 'phone'
  },
  {
    label: 'district',
    name: 'bairro',
    width: '200',
    hide: true
  },
  {
    label: 'street',
    name: 'rua',
    width: '200',
    hide: true
  },
  {
    label: 'number',
    name: 'nº',
    width: '80',
    hide: true
  },
  {
    label: 'cep',
    name: 'cep',
    width: '100',
    hide: true,
    mask: 'cep'
  },
  {
    label: 'complement',
    name: 'complemento',
    width: '200',
    hide: true
  },
  {
    label: 'latitude',
    name: 'latitude',
    width: '180',
    hide: true
  },
  {
    label: 'longitude',
    name: 'longitude',
    width: '180',
    hide: true
  },
  {
    label: 'plan_uuid',
    name: 'Plano',
    width: '200',
    hide: true
  }
]

export const defaultValuedataForm = {
  name: '',
  cpf: '',
  email: '',
  password: '',
  document: '',
  phone_number: '',
  district: '',
  street: '',
  number: '',
  cep: '',
  complement: '',
  latitude: '',
  longitude: '',
  plan_uuid: ''
}

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    xs: 12,
    md: 8
  },
  {
    id: uniqueId(),
    label: 'CPF',
    value: 'cpf',
    type: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'RG',
    value: 'document',
    type: 'required',
    xs: 6,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Telefone',
    value: 'phone_number',
    type: 'phone',
    mask: 'phone',
    xs: 12,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Email',
    value: 'email',
    type: 'required',
    xs: 12,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Password',
    value: 'password',
    type: 'required',
    xs: 12,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Cep',
    value: 'cep',
    type: 'number',
    mask: 'cep',
    xs: 4,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Bairro',
    value: 'district',
    type: 'required',
    xs: 8,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Rua',
    value: 'street',
    type: 'required',
    xs: 10,
    md: 10
  },
  {
    id: uniqueId(),
    label: 'Número',
    value: 'number',
    type: 'number',
    xs: 2,
    md: 2
  },
  {
    id: uniqueId(),
    label: 'Complemento',
    value: 'complement',
    type: 'required',
    xs: 12,
    md: 12
  }
]

export const dataFormEdit = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    mask: 'string',
    xs: 12,
    md: 8
  },
  {
    id: uniqueId(),
    label: 'CPF',
    value: 'cpf',
    type: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'RG',
    value: 'document',
    type: 'required',
    xs: 6,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Telefone',
    value: 'phone_number',
    type: 'phone',
    mask: 'phone',
    xs: 12,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Cep',
    value: 'cep',
    type: 'number',
    mask: 'cep',
    xs: 4,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Bairro',
    value: 'district',
    type: 'required',
    xs: 8,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Rua',
    value: 'street',
    type: 'required',
    xs: 10,
    md: 10
  },
  {
    id: uniqueId(),
    label: 'Número',
    value: 'number',
    type: 'number',
    xs: 2,
    md: 2
  },
  {
    id: uniqueId(),
    label: 'Complemento',
    value: 'complement',
    type: 'required',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    label: 'Latitude',
    value: 'latitude',
    type: 'required',
    xs: 6,
    md: 6
  },
  {
    id: uniqueId(),
    label: 'Longitude',
    value: 'longitude',
    type: 'required',
    xs: 6,
    md: 6
  }
]

export const dataFormShow = [
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Nome',
    value: 'name',
    mask: 'string',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'CPF',
    value: 'cpf',
    mask: 'cpf',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'RG',
    value: 'document',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Telefone',
    value: 'phone_number',
    mask: 'phone',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Cep',
    value: 'cep',
    mask: 'cep',
    xs: 4,
    md: 6
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Bairro',
    value: 'district',
    xs: 8,
    md: 6
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Rua',
    value: 'street',
    xs: 10,
    md: 10
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Número',
    value: 'number',
    xs: 2,
    md: 2
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Complemento',
    value: 'complement',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    label: 'Latitude',
    value: 'latitude',
    type: 'required',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Longitude',
    value: 'longitude',
    type: 'required',
    xs: 6,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Plano',
    value: 'plan_uuid',
    type: 'required',
    xs: 6,
    md: 4
  }
]
