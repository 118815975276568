export const Container = {
  display: 'flex',
  height: '100vh'
}

export const BoxL = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
}

export const BoxR = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

export const BoxRNone = {
  height: '100%',
  width: '100%',
  padding: '0px 15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}

export const Img = {
  margin: '5%',
  width: '20%'
}

export const ImgRR = {
  margin: '5%',
  width: '20%',
  display: 'flex',
  alignSelf: 'flex-end'
}

export const Logo = {
  margin: '10% 20%',
  width: '50%'
}

export const None = {
  display: 'none'
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '60vh',
  width: '30vw'
}
