import { uniqueId } from 'lodash'

export const columns = [
  {
    label: 'uuid',
    name: 'uuid',
    width: '320',
    hide: true
  },
  {
    label: 'model',
    name: 'modelo',
    width: '200',
    hide: false,
    mask: 'string'
  },
  {
    label: 'license_plate',
    name: 'placa',
    width: '130',
    hide: false,
    mask: 'placa'
  },
  {
    label: 'category',
    name: 'categoria',
    width: '130',
    hide: false,
    mask: 'string'
  }
]

export const defaultValuedataForm = {
  model: '',
  license_plate: '',
  category: ''
}

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Modelo',
    value: 'model',
    type: 'required',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    label: 'Placa',
    value: 'license_plate',
    type: 'placa',
    mask: 'placa',
    xs: 12,
    md: 12
  }
]

export const dataFormEdit = [
  {
    id: uniqueId(),
    label: 'Modelo',
    value: 'model',
    type: 'required',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    label: 'Placa',
    value: 'license_plate',
    type: 'required',
    mask: 'placa',
    xs: 12,
    md: 12
  }
]

export const dataFormShow = [
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Modelo',
    value: 'model',
    mask: 'string',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Placa',
    value: 'license_plate',
    xs: 12,
    md: 12
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Categoria',
    value: 'category',
    xs: 12,
    md: 12
  }
]
