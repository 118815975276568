import { uniqueId } from 'lodash'

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'label',
    type: 'required',
    xs: 12,
    md: 12
  }
]

export const defaultValuedataForm = {
  label: ''
}
