import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'

import React from 'react'
import PropTypes from 'prop-types'

import { AppBar } from './styles'

const Header = (props) => {
  return (
      <AppBar position="fixed" open={props.open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.onClick}
            edge="start"
            sx={{ mr: 2, ...(props.open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Typography color="#ffffff" variant="h6" component="div">
              Painel Visão
            </Typography>
            {/* <Typography 
              sx={{ fontSize: '8px', marginTop: '5px' }} 
              color="#ffffff" 
              component="div"
            >
            ®
            </Typography> */}
          </Box>
        </Toolbar>
      </AppBar>
  )
}

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Header
