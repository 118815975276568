export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '300px',
  width: '400px'
}

export const BoxButton = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly'
}

export const Button = {
  color: '#FFFFFF',
  background: '#0858dd'
}
