import React from 'react'

const Main = () => {
  return (
    <div>
      Main
    </div>
  )
}

export default Main
