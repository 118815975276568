export const Container = {
  display: 'flex',
  flexDirection: 'column'
}

export const Text = {
  marginBottom: '10px'
}

export const Button = {
  width: '8rem',
  marginLeft: '20px'
}

export const ButtonBox = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}

export const Autocomplete = {
  width: 300,
  marginTop: '20px',
  marginBottom: '20px'
}
