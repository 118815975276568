import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as data from './data'
import * as styles from './styles'

import Alert from '../../components/Alert'
import Table from '../../components/Table'
import HTable from '../../components/HeaderTable'
import FormVigilant from '../../components/FormVigilant'
import api from '../../services/api'

import { mask } from '../../utils/mask'
import { routes as apiRoutes } from '../../routes/data'
import { validators } from '../../utils/validators'

const Vigilant = () => {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [autocompleteValue, setAutocompleteValue] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')
  const [msg, setMsg] = React.useState('')

  const [userMenu, setUserMenu] = React.useState('')
  const [vigilantData, setVigilantData] = React.useState([])
  const [formData, setFormData] = React.useState(data.defaultValuedataForm)
  const [formVehicleData, setFormVehicleData] = React.useState(data.defaultDataVehicle)
  const [dataVehicle, setDataVehicle] = React.useState([])
  const [getVehicle, setGetVehicle] = React.useState([])
  const [category, setCategory] = React.useState('')
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [uuid, setUuid] = React.useState('')
  const [sroutes, setSRoutes] = React.useState([])
  const [selectedRoute, setSelectedRoute] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue
    })
  }

  const handleChangeVehicleForm = (name, newValue) => {
    setFormVehicleData({
      ...formVehicleData,
      [name]: newValue
    })
  }

  const validateForm = (form, value) => {
    let temp = false
    for (let i = 0; i < form.length; i++) {
      temp = validators(form[i].type, value[form[i].value]).err
      if (temp === true) break
    }
    return temp
  }

  const handleSubmit = async (type) => {
    setIsSubmit(true)
    setError(false)
    setMsg('')
    if (validateForm(data.dataForm, formData) === false) {
      setLoading(true)
      setOpen(true)

      const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

      if (!authData) {
        return
      }

      const config = {
        headers: {
          Authentication: authData.token
        }
      }

      let sendData = {}

      let role = await (await api.get('/role/all/', config)).data
      role = role.filter(element => element.name === 'Vigilante')[0]
      if (role === null) {
        return
      }

      if (type === 'new') {
        if (category === 'Pessoal') {
          sendData = {
            login_info: {
              email: formData.email,
              password: formData.password,
              role_uuid: role.uuid
            },
            vigilant_info: {
              name: formData.name,
              cpf: formData.cpf,
              document: formData.document,
              phone_number: formData.phone_number,
              license: formData.license
            },
            vehicle: {
              model: formVehicleData.model,
              license_plate: formVehicleData.license_plate.replace('-', ''),
              category
            }
          }
        } else {
          const aux = getVehicle.filter(
            element => element.license_plate === inputValue.split(' - ')[1].replace('-', ''))

          sendData = {
            login_info: {
              email: formData.email,
              password: formData.password,
              role_uuid: role.uuid
            },
            vigilant_info: {
              name: formData.name,
              cpf: formData.cpf,
              document: formData.document,
              phone_number: formData.phone_number,
              license: formData.license,
              vehicle_uuid: aux[0].uuid || formData.vehicle_uuid
            }
          }
        }

        api.post('/vigilant/', sendData, config).then(() => {
          setLoading(false)
        }).catch((err) => {
          if (err?.response?.data?.detail[0]?.msg) {
            setMsg(err.response.data.detail[0].msg)           
          }
          setError(true)
          setLoading(false)
        })
      } else if (type === 'edit') {
        if (inputValue !== '') {
          const aux = getVehicle.filter(
            element => element.license_plate === inputValue.split(' - ')[1].replace('-', ''))[0]
          sendData = {
            name: formData.name,
            cpf: formData.cpf,
            document: formData.document,
            phone_number: formData.phone_number,
            license: formData.license,
            route_uuid: selectedRoute,
            vehicle_uuid: aux.uuid
          }
        } else {
          sendData = {
            name: formData.name,
            cpf: formData.cpf,
            document: formData.document,
            phone_number: formData.phone_number,
            license: formData.license,
            route_uuid: selectedRoute
          }
        }

        api.put(`/vigilant/${formData.uuid}`, sendData, config).then(() => {
          setLoading(false)
        }).catch((err) => {
          console.log(err)
          setError(true)
          setLoading(false)
        })
      }
    }
  }

  const handleDelete = () => {
    setIsDelete(false)
    setError(false)
    setLoading(true)

    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      return
    }

    const config = {
      headers: {
        Authentication: authData?.token || ''
      }
    }

    api.delete(`/vigilant/${uuid}`, config).then(() => {
      setLoading(false)
    }).catch(() => {
      setError(true)
      setLoading(false)
    })
  }

  const refreshVehicle = async (value) => {
    if (value) {
      const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

      if (!authData) {
        return
      }

      const config = {
        headers: {
          Authentication: authData?.token || ''
        }
      }

      const resp = await api.get(`/vehicle/${value}`, config)

      if (resp.data) {
        setFormVehicleData({
          model: resp.data.model,
          license_plate: resp.data.license_plate,
          category: resp.data.category
        })
        setAutocompleteValue(
          `${mask('string', resp.data.model)} - ${mask('placa', resp.data.license_plate)}`
        )
        setCategory(resp.data.category)
      }
    } else {
      setFormVehicleData({
        model: '',
        license_plate: '',
        category: ''
      })
      setCategory('')
    }
  }

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(apiRoutes.login)
    }

    const config = {
      headers: {
        Authentication: authData?.token || ''
      }
    }

    api.get('/route/all/', config).then((resp) => {
      setSRoutes(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [navigate])

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      return
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.get('/vigilant/all/', config).then((resp) => {
      setVigilantData(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      return
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.get('/vehicle/all/', config).then((resp) => {
      const aux = []
      if (resp.data) {
        setGetVehicle(resp.data)
        for (let i = 0; i < resp.data.length; i += 1) {
          if (resp.data[i].category === 'Empresa') {
            aux.push(
              `${mask('string', resp.data[i].model)} - ${mask('placa', resp.data[i].license_plate)}`
            )
          }
        }

        setDataVehicle(aux)
      }
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <Container sx={styles.Container}>
      { userMenu === 'new'
        ? <FormVigilant
            cancel={() => setUserMenu('')}
            category={category}
            data={data.dataForm}
            handleSubmit={() => handleSubmit('new')}
            isSubmit={isSubmit}
            loading={loading}
            onChange={handleChangeForm}
            onVehicleChange={handleChangeVehicleForm}
            onCategoryChange={(value) => setCategory(value)}
            text='Cadastro de Vigilante'
            type='form'
            value={formData}
            vehicleForm={data.dataVehicleForm}
            vehicleValue={formVehicleData}
            autocompleteValue={autocompleteValue}
            autocomplete={setAutocompleteValue}
            inputValue={inputValue}
            input={setInputValue}
            autocompleteData={dataVehicle}
          />
        : userMenu === 'edit'
          ? <>
            <FormVigilant
              cancel={() => setUserMenu('')}
              category={category}
              data={data.dataFormEdit}
              handleSubmit={() => handleSubmit('edit')}
              isSubmit={isSubmit}
              loading={loading}
              onChange={handleChangeForm}
              onVehicleChange={handleChangeVehicleForm}
              onCategoryChange={(value) => setCategory(value)}
              text='Editor de Cadastro de Vigilante'
              type='form'
              value={formData}
              vehicleForm={data.dataVehicleForm}
              vehicleValue={formVehicleData}
              autocompleteValue={autocompleteValue}
              autocomplete={setAutocompleteValue}
              inputValue={inputValue}
              input={setInputValue}
              autocompleteData={dataVehicle}
              routesData={sroutes}
              selectedRoute={selectedRoute}
              setSelectedRoute={(value) => setSelectedRoute(value)}
            />
          </>
          : userMenu === 'show'
            ? <FormVigilant
                data={data.dataFormShow}
                value={formData}
                text='Vigilante'
                type='perfil'
                cancel={() => setUserMenu('')}
              />
            : <>
          <HTable text="Vigilantes" onClick={() => {
            setFormData(data.defaultValuedataForm)
            setFormVehicleData(data.defaultDataVehicle)
            setAutocompleteValue('')
            setCategory('')
            setUserMenu('new')
          }} />
          <CssBaseline />
          <Table
            rows={vigilantData}
            columns={data.columns}
            show={(id) => {
              let vigilantShow = vigilantData.filter(element => element.uuid === id)[0]
              const vehicleShow = getVehicle.filter(element => element.uuid === vigilantShow.vehicle_uuid)[0]
              const routeShow = sroutes.filter(element => element.uuid === vigilantShow.route_uuid)[0]
              vigilantShow = {
                ...vigilantShow,
                category: vehicleShow?.category || '',
                model: vehicleShow?.model || '',
                license_plate: vehicleShow?.license_plate || '',
                route: routeShow?.name || ''
              }

              setFormData(vigilantShow)
              setUserMenu('show')
            }}
            edit={(id) => {
              const vigilant = vigilantData.filter(element => element.uuid === id)[0]
              setFormData(vigilant)
              refreshVehicle(vigilant.vehicle_uuid)
              setSelectedRoute(vigilant.route_uuid)
              setUserMenu('edit')
            }}
            delete={(id) => {
              setIsDelete(true)
              setOpen(true)
              setUuid(id)
            }}
          />
        </>
       }
       <Alert
        open={open}
        loading={loading}
        error={error}
        msg={msg}
        handleModalClose={() => setOpen(false)}
        handleClose={() => document.location.reload(true)}
        delete={isDelete}
        handleDelete={handleDelete}
       />
    </Container>
  )
}

export default Vigilant
