import React from 'react'
import PropTypes from 'prop-types'

import { DrawerHeader, Main } from './styles'

const Body = (props) => {
  return (
    <Main open={props.open}>
      <DrawerHeader />
      {props.children}
    </Main>
  )
}

Body.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
}

export default Body
