import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

const Alert = (props) => {
  return (
    (props.delete === false
      ? <Dialog
      open={props.open}
      aria-labelledby="responsive-dialog-title"
    >
      { props.loading
        ? (
            <Box sx={styles.Dialog}>
              <TimelapseIcon sx={{ fontSize: 90 }} />
              <Typography variant="h4">Aguarde</Typography>
            </Box>
          )
        : props.error
          ? (
            <Box sx={styles.Dialog}>
              <CancelIcon sx={{ fontSize: 90 }} color="error" />
              <Typography variant="h5">{props.msg !== '' ? props.msg : 'Ocorreu um erro, tente novamente!'}</Typography>
              <Button
                onClick={props.handleModalClose}
                className="animate__animated animate__fadeIn"
                size="large"
                sx={styles.Button}
                variant="contained"
              >
                Confirmar
              </Button>
            </Box>
            )
          : (
            <Box sx={styles.Dialog}>
              <CheckCircleIcon sx={{ fontSize: 90 }} color="success" />
              <Typography variant="h5">Operação Realizada com Sucesso</Typography>
              <Button
                onClick={props.handleClose}
                className="animate__animated animate__fadeIn"
                size="large"
                sx={styles.Button}
                variant="contained"
              >
                Confirmar
              </Button>
            </Box>
            )
        }
      </Dialog>
      : <Dialog
          open={props.open}
          aria-labelledby="responsive-dialog-title"
        >
        <Box sx={styles.Dialog}>
          <CancelIcon sx={{ fontSize: 90 }} color="error" />
          <Typography variant="h5">Essa Operação é Irreversível, deseja continuar?</Typography>
          <Box sx={styles.BoxButton}>
            <Button
              onClick={props.handleModalClose}
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => props.handleDelete()}
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Dialog>)
  )
}

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  delete: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export default Alert
