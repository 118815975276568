import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'

import * as styles from './styles'

import Form from '../Form'
import Perfil from '../Perfil'

const FormVigilant = (props) => {
  return (
    <Container sx={styles.Container}>
      <CssBaseline />
      <Typography variant="h5" sx={styles.Text}>{props.text}</Typography>
      { props.type === 'form'
        ? <>
        <Form
          data={props.data}
          value={props.value}
          onChange={props.onChange}
          isSubmit={props.isSubmit}
        />
        <FormControl>
          <FormLabel>Categoria do Veículo</FormLabel>
          <RadioGroup
            value={props.category}
            onChange={(e) => props.onCategoryChange(e.target.value)}
          >
            <FormControlLabel value="Pessoal" control={<Radio />} label="Pessoal" />
            <FormControlLabel value="Empresa" control={<Radio />} label="Empresa" />
          </RadioGroup>
        </FormControl>
        { props.category === 'Pessoal'
          ? (
            <Form
              data={props.vehicleForm}
              value={props.vehicleValue}
              onChange={props.onVehicleChange}
              isSubmit={props.isSubmit}
            />
            )
          : props.category === 'Empresa' && (
            <Autocomplete
              value={props.autocompleteValue}
              onChange={(_event, newValue) => {
                props.autocomplete(newValue)
              }}
              inputValue={props.inputValue}
              onInputChange={(_event, newInputValue) => {
                props.input(newInputValue)
              }}
              options={props.autocompleteData}
              sx={styles.Autocomplete}
              renderInput={(params) => <TextField {...params} label="Veículos" />}
            />
          )}
          {props.routesData.length > 0 &&
          <FormControl fullWidth>
            <InputLabel>Rotas</InputLabel>
            <Select
              label="Rotas"
              value={props.selectedRoute}
              sx={styles.Select}
              onChange={(e) => props.setSelectedRoute(e.target.value)}
            >
              {/* <MenuItem key={uniqueId()} value={null}>Nenhuma</MenuItem> */}
              {props.routesData.map((element) => (
                <MenuItem key={uniqueId()} value={element.uuid}>{element.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          }
        </>
        : <Perfil
          data={props.data}
          value={props.value}
        />
      }
      <Box sx={styles.ButtonBox}>
        <Button
          size="medium"
          type="submit"
          sx={styles.Button}
          variant="contained"
          onClick={props.cancel}
        >
          Voltar
        </Button>
        { props.type === 'form' &&
          <Button
            size="medium"
            type="submit"
            sx={styles.Button}
            variant="contained"
            onClick={props.handleSubmit}
          >
            { props.loading ? <CircularProgress /> : 'Confirmar' }
          </Button>
        }
      </Box>
    </Container>
  )
}

FormVigilant.propTypes = {
  value: PropTypes.object.isRequired,
  isSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  onCategoryChange: PropTypes.func,
  vehicleForm: PropTypes.array.isRequired,
  vehicleValue: PropTypes.object.isRequired,
  onVehicleChange: PropTypes.func,
  autocompleteValue: PropTypes.string,
  autocomplete: PropTypes.func,
  inputValue: PropTypes.string,
  input: PropTypes.func,
  autocompleteData: PropTypes.array,
  routesData: PropTypes.array,
  selectedRoute: PropTypes.string,
  setSelectedRoute: PropTypes.func
}

FormVigilant.defaultProps = {
  isSubmit: false,
  onChange: () => {},
  handleSubmit: () => {},
  loading: false,
  category: '',
  onCategoryChange: () => {},
  onVehicleChange: () => {},
  autocompleteValue: '',
  autocomplete: () => {},
  inputValue: '',
  input: () => {},
  autocompleteData: [],
  routesData: [],
  selectedRoute: '',
  setSelectedRoute: () => {},
  vehicleForm: [],
  vehicleValue: ''
}

export default FormVigilant
