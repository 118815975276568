import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

import Form from '../Form'
import Perfil from '../Perfil'

const FormPlan = (props) => {
  return (
    <Container sx={styles.Container}>
      <CssBaseline />
      <Typography variant="h5" sx={styles.Text}>{props.text}</Typography>
      { props.type === 'form'
        ? <Form
          data={props.data}
          value={props.value}
          onChange={props.onChange}
          isSubmit={props.isSubmit}
        />
        : <Perfil
          data={props.data}
          value={props.value}
        />
      }
      <Box sx={styles.ButtonBox}>
        <Button
          size="medium"
          type="submit"
          sx={styles.Button}
          variant="contained"
          onClick={props.cancel}
        >
          Voltar
        </Button>
        { props.type === 'form' &&
          <Button
            size="medium"
            type="submit"
            sx={styles.Button}
            variant="contained"
            onClick={props.handleSubmit}
          >
            { props.loading ? <CircularProgress /> : 'Confirmar' }
          </Button>
        }
      </Box>
    </Container>
  )
}

FormPlan.propTypes = {
  value: PropTypes.object.isRequired,
  isSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

FormPlan.defaultProps = {
  isSubmit: false,
  onChange: () => {},
  handleSubmit: () => {},
  loading: false
}

export default FormPlan
