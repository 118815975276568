import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import * as Pages from '../pages'

import { routes } from './data'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.login} element={<Pages.Login />} />
        <Route path={routes.home} element={<Pages.Home />}>
          <Route path={routes.main} element={<Pages.Main />} />
          <Route path={routes.user} element={<Pages.User />} />
          <Route path={routes.vehicle} element={<Pages.Vehicle />} />
          <Route path={routes.vigilant} element={<Pages.Vigilant />} />
          <Route path={routes.plan} element={<Pages.Plan />} />
          <Route path={routes.region} element={<Pages.Region />} />
          <Route path={routes.calls} element={<Pages.Calls />} />
          <Route path={routes.map} element={<Pages.Map />} />
          <Route path={routes.offers} element={<Pages.Offers />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
