export const routes = {
  login: process.env.PUBLIC_URL + '/',
  home: process.env.PUBLIC_URL + '/dashboard',
  main: process.env.PUBLIC_URL + '/dashboard/main',
  map: process.env.PUBLIC_URL + '/dashboard/mapa',
  offers: process.env.PUBLIC_URL + '/dashboard/ofertas',
  plan: process.env.PUBLIC_URL + '/dashboard/planos',
  region: process.env.PUBLIC_URL + '/dashboard/rotas',
  user: process.env.PUBLIC_URL + '/dashboard/usuario',
  vigilant: process.env.PUBLIC_URL + '/dashboard/vigilante',
  vehicle: process.env.PUBLIC_URL + '/dashboard/veiculo',
  calls: process.env.PUBLIC_URL + '/dashboard/chamadas'
}
