import { uniqueId } from 'lodash'

export const columns = [
  {
    label: 'uuid',
    name: 'uuid',
    width: '320',
    hide: true
  },
  {
    label: 'name',
    name: 'nome',
    width: '200',
    hide: false
  },
  {
    label: 'price',
    name: 'preço',
    width: '120',
    hide: false,
    mask: 'moeda'
  },
  {
    label: 'coverage_area',
    name: 'cobertura',
    width: '150',
    hide: false
  },
  {
    label: 'description',
    name: 'descrição',
    width: '200',
    hide: true
  }
]

export const defaultValuedataForm = {
  model: '',
  price: '0',
  coverage_area: '',
  description: ''
}

export const dataForm = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Preço',
    value: 'price',
    type: 'required',
    mask: 'moeda',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Cobertura',
    value: 'coverage_area',
    type: 'required',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Descrição',
    value: 'description',
    type: 'required',
    xs: 12,
    md: 12
  }
]

export const dataFormEdit = [
  {
    id: uniqueId(),
    label: 'Nome',
    value: 'name',
    type: 'required',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Preço',
    value: 'price',
    type: 'required',
    mask: 'moeda',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Cobertura',
    value: 'coverage_area',
    type: 'required',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    label: 'Descrição',
    value: 'description',
    type: 'required',
    xs: 12,
    md: 12
  }
]

export const dataFormShow = [
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Nome',
    value: 'name',
    mask: 'string',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Preço',
    value: 'price',
    mask: 'moeda',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Cobertura',
    value: 'coverage_area',
    mask: 'string',
    xs: 12,
    md: 4
  },
  {
    id: uniqueId(),
    variant: 'h7',
    label: 'Descrição',
    value: 'description',
    xs: 12,
    md: 12
  }
]
