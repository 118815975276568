import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import MapIcon from '@mui/icons-material/Map'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

import Form from '../Form'
import Perfil from '../Perfil'

const FormUser = (props) => {
  return (
    <Container sx={styles.Container}>
      <CssBaseline />
      <Typography variant="h5" sx={styles.Text}>{props.text}</Typography>
      { props.type === 'form' || props.type === 'edit'
        ? <Form
            data={props.data}
            value={props.value}
            onChange={props.onChange}
            isSubmit={props.isSubmit}
          />
        : <Perfil
          data={props.data}
          value={props.value}
        />
      }
      { props.type === 'edit' &&
        <>
          <MapIcon
            sx={{ fontSize: 40, marginBottom: 1 }}
            onClick={props.open}
          />
          <Autocomplete
            value={props.autocompleteValue}
            onChange={(_event, newValue) => {
              props.autocomplete(newValue)
            }}
            inputValue={props.inputValue}
            onInputChange={(_event, newInputValue) => {
              props.input(newInputValue)
            }}
            options={props.autocompleteData}
            sx={styles.Autocomplete}
            renderInput={(params) => <TextField {...params} label="Planos" />}
          />
        </>
        }
      <Box sx={styles.ButtonBox}>
        <Button
          size="medium"
          type="submit"
          sx={styles.Button}
          variant="contained"
          onClick={props.cancel}
        >
          Voltar
        </Button>
        { (props.type === 'form' || props.type === 'edit') &&
          <Button
            size="medium"
            type="submit"
            sx={styles.Button}
            variant="contained"
            onClick={props.handleSubmit}
          >
            { props.loading ? <CircularProgress /> : 'Confirmar' }
          </Button>
        }
      </Box>
    </Container>
  )
}

FormUser.propTypes = {
  value: PropTypes.object.isRequired,
  isSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string,
  autocomplete: PropTypes.func,
  inputValue: PropTypes.string,
  input: PropTypes.func,
  autocompleteData: PropTypes.array,
  open: PropTypes.func
}

FormUser.defaultProps = {
  isSubmit: false,
  onChange: () => {},
  handleSubmit: () => {},
  loading: false,
  autocompleteValue: '',
  autocomplete: () => {},
  inputValue: '',
  input: () => {},
  autocompleteData: []
}

export default FormUser
