import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMap,
  useMapEvents
} from 'react-leaflet'

import * as data from './data'
import * as styles from './styles'

import Alert from '../../components/Alert'
import Form from '../../components/Form'
import * as Icon from '../../components/icon'
import { routes } from '../../routes/data'
import api from '../../services/api'

const Tile = (props) => {
  useMapEvents({
    click: (e) => {
      if (props.route === false) {
        const { lat, lng } = e.latlng
        props.setCoords([lat, lng])
        props.setRoute(true)
        props.setPoly([
          [lat, lng],
          [lat - 0.001, lng],
          [lat - 0.001, lng - 0.001],
          [lat, lng - 0.001],
          [lat, lng]
        ])
      }
    }
  })
  return null
}

Tile.propTypes = {
  route: PropTypes.bool.isRequired,
  setCoords: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired,
  setPoly: PropTypes.func.isRequired
}

const Region = () => {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [msgOpen, setMsgOpen] = React.useState(false)
  const [label, setLabel] = React.useState(data.defaultValuedataForm)
  const [labelOpen, setLabelOpen] = React.useState(false)
  const [isSubmit, setIsSubmit] = React.useState(false)

  const [menu, setMenu] = React.useState('')
  const [route, setRoute] = React.useState(false)
  const [coords, setCoords] = React.useState([])

  const [listRoutes, setListRoutes] = React.useState([])
  const [isActived, setIsActived] = React.useState('')

  const defaultCenter = [-16.072870129464917, -57.68599658169415]
  const [center, setCenter] = React.useState(defaultCenter)

  const defaultPoly = [
    [center[0], center[1]],
    [center[0] - 0.001, center[1]],
    [center[0] - 0.001, center[1] - 0.001],
    [center[0], center[1] - 0.001],
    [center[0], center[1]]
  ]

  const [poly, setPoly] = React.useState(defaultPoly)

  const markerRef = React.useRef(null)
  const markerRef2 = React.useRef(null)
  const markerRef3 = React.useRef(null)
  const markerRef4 = React.useRef(null)

  const ChangeView = () => {
    const map = useMap()
    map.setView(center)
    return null
  }

  const updatePoly = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][0] !== poly[i][0] || array[i][1] !== poly[i][1]) {
        setCenter(array[i])
      }
    }
    setPoly(array)
  }

  const handleChangeForm = (name, newValue) => {
    setLabel({
      ...label,
      [name]: newValue
    })
  }

  const handleNameSubmit = () => {
    setIsSubmit(true)
    if (label !== '') {
      handleSubmit()
    }
  }

  const eventHandlers = React.useMemo(
    () => ({
      dragend: () => {
        const aux = [
          [markerRef.current.getLatLng().lat, markerRef.current.getLatLng().lng],
          [markerRef2.current.getLatLng().lat, markerRef2.current.getLatLng().lng],
          [markerRef3.current.getLatLng().lat, markerRef3.current.getLatLng().lng],
          [markerRef4.current.getLatLng().lat, markerRef4.current.getLatLng().lng],
          [markerRef.current.getLatLng().lat, markerRef.current.getLatLng().lng]
        ]

        updatePoly(aux)
      }
    }), [])

  const handleSubmit = () => {
    if (route === true) {
      setLoading(true)
      setOpen(true)

      const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

      if (!authData) {
        return
      }

      const config = {
        headers: {
          Authentication: authData?.token || ''
        }
      }

      const sendData = {
        name: label.label,
        coordinates: JSON.stringify(poly)
      }

      if (menu === 'new') {
        api.post('/route/', sendData, config)
          .then(() => {
            setLoading(false)
          }).catch((err) => {
            console.log(err)
            setError(true)
            setLoading(false)
          })
      } else if (menu === 'edit') {
        api.put(`/route/${isActived}`, sendData, config)
          .then(() => {
            console.log('aqui')
            setLoading(false)
          }).catch(() => {
            setError(true)
            setLoading(false)
          })
      }
    }
  }

  const handleEdit = () => {
    if (isActived !== '') {
      const element = listRoutes.filter((element) => element.uuid === isActived)[0]
      setLabel({ label: element.name })
      const coords = JSON.parse(element.coordinates)
      setPoly(coords)
      setCenter([
        (coords[0][0] + coords[2][0]) / 2, (coords[0][1] + coords[2][1]) / 2
      ])
      setRoute(true)
      setMenu('edit')
    } else {
      setMsgOpen(true)
    }
  }

  const handleDelete = () => {
    setIsDelete(false)
    setError(false)
    setLoading(true)

    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      return
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.delete(`/route/${isActived}`, config).then(() => {
      setLoading(false)
    }).catch(() => {
      setError(true)
      setLoading(false)
    })
  }

  const handleActived = (element) => {
    setIsActived(element.uuid)
    const coords = JSON.parse(element.coordinates)
    setCoords(coords)
    setCenter([
      (coords[0][0] + coords[2][0]) / 2, (coords[0][1] + coords[2][1]) / 2
    ])
  }

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(routes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    api.get('/route/all/', config).then((resp) => {
      setListRoutes(resp.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <Container sx={styles.Container}>
      <CssBaseline />
      { menu === 'new' || menu === 'edit'
        ? <>
          <Box sx={styles.Box}>
            <MapContainer
              center={center}
              zoom={14}
              zoomControl={true}
              style={{ width: '100%', height: '100%' }}
            >
              <ChangeView center={center} zoom={14} />
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Tile route={route} setCoords={setCenter} setRoute={setRoute} setPoly={setPoly} />
              {route === true && <>
                <Polygon pathOptions={{ color: 'blue' }} positions={poly} />
                <Marker
                  position={poly[0]}
                  icon={Icon.blackIcon}
                  ref={markerRef}
                  draggable={true}
                  eventHandlers={eventHandlers}
                />
                <Marker
                  position={poly[1]}
                  icon={Icon.blackIcon}
                  ref={markerRef2}
                  draggable={true}
                  eventHandlers={eventHandlers}
                />
                <Marker
                  position={poly[2]}
                  icon={Icon.blackIcon}
                  ref={markerRef3}
                  draggable={true}
                  eventHandlers={eventHandlers}
                />
                <Marker
                  position={poly[3]}
                  icon={Icon.blackIcon}
                  ref={markerRef4}
                  draggable={true}
                  eventHandlers={eventHandlers}
                />
              </>}
            </MapContainer>
          </Box>
          <Stack sx={styles.Stack} direction="row" spacing={3}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setMenu('')}
            >
              Voltar
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setRoute(false)}
            >
              Limpar
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setLabelOpen(true)}
            >
              Salvar
            </Button>
          </Stack>
        </>
        : <>
        <Box sx={styles.Header}>
          <Typography variant="h5">Rotas</Typography>
          <Stack direction="row" spacing={2}>
          <Button
              sx={styles.Button}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                if (isActived !== '') {
                  setIsDelete(true)
                  setOpen(true)
                } else {
                  setMsgOpen(true)
                }
              }}
            >
              Excluir
            </Button>
            <Button
              sx={styles.Button}
              size="large"
              type="submit"
              variant="contained"
              onClick={handleEdit}
            >
              Editar
            </Button>
            <Button
              sx={styles.Button}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setMenu('new')}
            >
              Novo
            </Button>
          </Stack>
        </Box>
        <Box sx={styles.BoxMenu}>
          <Box sx={listRoutes.length > 0 ? styles.BoxLeft : {}}>
            <Stack direction="column">
              {listRoutes.map((element) => (
                <ListItem key={element.uuid}>
                  <ListItemButton
                    sx={isActived === element.uuid ? styles.ActivedListButton : styles.ListButton}
                    onClick={() => handleActived(element)}
                  >
                    <ListItemText sx={{ color: '#ffffff' }} primary={element.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </Stack>
          </Box>
          <Box sx={listRoutes.length > 0 ? styles.BoxRight : styles.Box}>
            <MapContainer
              center={center}
              zoom={14}
              zoomControl={true}
              style={{ width: '100%', height: '100%' }}
            >
              <ChangeView center={center} zoom={14} />
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Polygon pathOptions={{ color: 'blue' }} positions={coords} />
            </MapContainer>
          </Box>
        </Box>
      </>
    }
      <Alert
        open={open}
        loading={loading}
        error={error}
        handleModalClose={() => setOpen(false)}
        handleClose={() => document.location.reload(true)}
        delete={isDelete}
        handleDelete={handleDelete}
      />
      <Dialog
        open={msgOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.Dialog}>
          <Typography variant="h4">Selecione uma Rota Primeiro!</Typography>
          <Button
            onClick={() => setMsgOpen(false)}
            size="large"
            variant="contained"
          >Confirmar</Button>
        </Box>
      </Dialog>
      <Dialog
        open={labelOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.Dialog}>
          <Typography variant="h5">Digite um Nome para a Rota</Typography>
          <Form
            data={data.dataForm}
            value={label}
            onChange={handleChangeForm}
            isSubmit={isSubmit}
          />
          <Stack direction="row" spacing={5}>
            <Button
              onClick={() => setLabelOpen(false)}
              size="large"
              variant="contained"
              sx={styles.Button}
            >Cancelar</Button>
            <Button
              onClick={handleNameSubmit}
              size="large"
              variant="contained"
              sx={styles.Button}
            >Confirmar</Button>
          </Stack>
        </Box>
      </Dialog>
    </Container>
  )
}

export default Region
