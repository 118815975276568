// import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'

import React from 'react'
import { Outlet } from 'react-router-dom'

import * as data from './data'
import * as styles from './styles'

import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Body from '../../components/Body'

const Home = () => {
  const [open, setOpen] = React.useState(true)

  return (
    <Container maxWidth="none" sx={styles.Container}>
      <CssBaseline />
      <Header open={open} onClick={() => setOpen(true)} />
      <Menu
        open={open}
        onClick={() => setOpen(false)}
        accordion={data.MenuAccordion}
        list={data.menuList}
      />
      <Body open={open}>
        <Outlet />
      </Body>
    </Container>
  )
}

export default Home
