import React from 'react'

import Routes from './routes'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'leaflet/dist/leaflet.css'

const App = () => <Routes />

export default App
