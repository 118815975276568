/* eslint-disable no-param-reassign */
const Captalize = (value) => {
  value = value.split(' ')
  for (let i = 0; i < value.length; i += 1) {
    if (value[i].length > 3) {
      value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1)
    } else if (value[i].length === 3 && value[i].charAt(2) !== 's') {
      value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1)
    }
  }

  return value.join(' ')
}

const Cpf = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 3 || i === 6) {
      aux = `${aux}.${value[i]}`
    } else if (i === 9) {
      aux = `${aux}-${value[i]}`
    } else if (i < 11) {
      aux += value[i]
    }
  }
  return aux
}

const Cnpj = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 2 || i === 5) {
      aux = `${aux}.${value[i]}`
    } else if (i === 8) {
      aux = `${aux}/${value[i]}`
    } else if (i === 12) {
      aux = `${aux}-${value[i]}`
    } else if (i < 14) {
      aux += value[i]
    }
  }
  return aux
}

const Cep = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 2) {
      aux = `${aux}.${value[i]}`
    } else if (i === 5) {
      aux = `${aux}-${value[i]}`
    } else if (i < 8) {
      aux += value[i]
    }
  }
  return aux
}

const Tel = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 0) {
      aux = `${aux}(${value[i]}`
    } else if (i === 2) {
      aux = `${aux}) ${value[i]}`
    } else if (value.length < 11 && i === 6) {
      aux = `${aux}-${value[i]}`
    } else if (value.length >= 11 && i === 7) {
      aux = `${aux}-${value[i]}`
    } else if (i < 11) {
      aux += value[i]
    }
  }
  return aux
}

const Moeda = (value) => {
  value = parseFloat(value).toString().split('').reverse()
  let aux = ''

  if (value.length < 3) {
    if (value.length === 1) {
      return `R$: 0,0${value[0]}`
    }
    return `R$: 0,${value[1]}${value[0]}`
  }
  for (let i = 0; i < value.length; i += 1) {
    if (i === 2) {
      aux = `${aux},${value[i]}`
    } else if (value.length > 4 && (i + 1) % 3 === 0) {
      aux = `${aux}.${value[i]}`
    } else {
      aux += value[i]
    }
  }

  return `R$ ${aux.split('').reverse().join('')}`
}

const Placa = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 3) {
      aux = `${aux}-${value[i]}`
    } else if (i < 7) {
      aux += value[i]
    }
  }
  return aux
}

const DateMask = (value) => {
  value = value.split('')
  let aux = ''

  for (let i = 0; i < value.length; i += 1) {
    if (i === 2) {
      aux = `${aux}/${value[i]}`
    } else if (i === 4) {
      aux = `${aux}/${value[i]}`
    } else {
      aux += value[i]
    }
  }
  return aux
}

export const mask = (type, value) => {
  switch (type) {
    case 'string':
      value = value.toLowerCase()
      value = Captalize(value)
      break
    case 'number':
      value = value.replace(/[^0-9]/g, '')
      break
    case 'cpf':
      value = value.replace(/[^0-9]/g, '')
      value = Cpf(value)
      break
    case 'cnpj':
      value = value.replace(/[^0-9]/g, '')
      value = Cnpj(value)
      break
    case 'cpf/cnpj':
      value = value.replace(/[^0-9]/g, '')
      if (value.length < 12) value = Cpf(value)
      else value = Cnpj(value)
      break
    case 'cep':
      value = value.replace(/[^0-9]/g, '')
      value = Cep(value)
      break
    case 'phone':
      value = value.replace(/[^0-9]/g, '')
      value = Tel(value)
      break
    case 'placa':
      value = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
      value = Placa(value)

      break
    case 'moeda':
      if (value.toString().split('').length < 24) {
        value = value.toString().replace(/[^0-9]/g, '')
        value = Moeda(value)
      } else {
        value = value.slice(0, 24)
      }
      break
    case 'date':
      value = value.replace(/[^0-9]/g, '')
      value = DateMask(value)
      break
    default:
      break
  }

  return value
}
