import CallIcon from '@mui/icons-material/Call'
import DoorBackIcon from '@mui/icons-material/DoorBack'
// import HomeIcon from '@mui/icons-material/Home'
import MapIcon from '@mui/icons-material/Map'
import PaymentsIcon from '@mui/icons-material/Payments'
import PersonIcon from '@mui/icons-material/Person'
import RouteIcon from '@mui/icons-material/Route'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports'
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import React from 'react'
import { uniqueId } from 'lodash'

import { routes } from '../../routes/data'

export const MenuAccordion = []

export const menuList = [
  // {
  //   id: uniqueId(),
  //   text: 'Início',
  //   icon: <HomeIcon />,
  //   link: routes.main
  // },
  {
    id: uniqueId(),
    text: 'Clientes',
    icon: <PersonIcon />,
    link: routes.user
  },
  {
    id: uniqueId(),
    text: 'Vigilantes',
    icon: <SportsMotorsportsIcon />,
    link: routes.vigilant
  },
  {
    id: uniqueId(),
    text: 'Veículos',
    icon: <TwoWheelerIcon />,
    link: routes.vehicle
  },
  {
    id: uniqueId(),
    text: 'Planos',
    icon: <PaymentsIcon />,
    link: routes.plan
  },
  {
    id: uniqueId(),
    text: 'Ofertas',
    icon: <LocalOfferIcon />,
    link: routes.offers
  },
  {
    id: uniqueId(),
    text: 'Rotas',
    icon: <RouteIcon />,
    link: routes.region
  },
  {
    id: uniqueId(),
    text: 'Chamadas',
    icon: <CallIcon />,
    link: routes.calls
  },
  {
    id: uniqueId(),
    text: 'Mapa',
    icon: <MapIcon />,
    link: routes.map
  },
  {
    id: uniqueId(),
    text: 'Sair',
    icon: <DoorBackIcon />,
    link: routes.login
  }
]
