export const Container = {
  display: 'flex',
  flexDirection: 'column'
}

export const Text = {
  marginBottom: '10px'
}

export const Box = {
  width: '100%',
  height: '70vh'
}
