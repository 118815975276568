import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  ptBR
} from '@mui/x-data-grid'

import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'

import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'

import * as styles from './styles'
import { mask } from '../../utils/mask'

function CustomPagination () {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(_event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

const Table = (props) => {
  const showItem = (id) => { props.show(id) }
  const editItem = (id) => { props.edit(id) }
  const deleteItem = (id) => { props.delete(id) }

  const renderColumn = (element) => (
    {
      field: element.name,
      renderHeader: () => (
        <Typography sx={styles.TextHeader}>
          {element.name}
        </Typography>
      ),
      width: element.width,
      hide: element.hide,
      editable: false,
      renderCell: (params) => (
        <Typography sx={styles.TextCell}>
          {
            element?.mask
              ? mask(element.mask, params.row[element.label])
              : params.row[element.label]
          }
        </Typography>
      )
    }
  )

  const createColumns = (columns) => {
    let aux = columns.map(
      (element) => renderColumn(element)
    )

    aux = [{
      field: 'actions',
      type: 'actions',
      renderHeader: () => (
        <Typography sx={styles.TextHeader}>
          Ações
        </Typography>
      ),
      width: 120,
      editable: false,
      getActions: (params) => [
        <GridActionsCellItem
          key={uniqueId()}
          icon={<VisibilityIcon />}
          label="Editar"
          onClick={() => showItem(params.id)}
        />,
        <GridActionsCellItem
          key={uniqueId()}
          icon={<EditIcon />}
          label="Editar"
          onClick={() => editItem(params.id)}
        />,
        <GridActionsCellItem
          key={uniqueId()}
          icon={<DeleteIcon />}
          label="Deletar"
          onClick={() => deleteItem(params.id)}
        />
      ]
    }, ...aux]

    return aux
  }

  return (
    <DataGrid
      sx={{ padding: 1, minHeight: '510px' }}
      autoHeight
      density="compact"
      rows={props.rows}
      getRowId={(row) => row.uuid}
      columns={createColumns(props.columns)}
      pageSize={10}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      components={{
        Toolbar: GridToolbar,
        Pagination: CustomPagination
      }}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
    />
  )
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  show: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired
}

export default Table
