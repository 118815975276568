const Cpf = (value) => {
  let resp = { err: false, value }
  value = value.split('')
  if (value.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value: value.join('')
    }
  } else {
    let d1 = 0
    for (let i = 0; i < 9; i++) {
      d1 += value[i] * (10 - i)
    }
    d1 = d1 % 11
    if (d1 < 2) {
      d1 = 0
    } else {
      d1 = 11 - d1
    }
    let d2 = 0
    for (let i = 0; i < 10; i++) {
      d2 += value[i] * (11 - i)
    }
    d2 = d2 % 11
    if (d2 < 2) {
      d2 = 0
    } else {
      d2 = 11 - d2
    }
    if (value[9] !== d1.toString() || value[10] !== d2.toString()) {
      resp = {
        err: true,
        msg: 'Cpf Inválido',
        value: value.join('')
      }
    }
  }
  return resp
}

const Tel = (value) => {
  let resp = { err: false, value }
  if (value.split('').length < 3) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 10) {
      resp = {
        err: true,
        msg: 'Número Inválido',
        value
      }
    }
  }
  return resp
}

const Number = (value) => {
  let resp = {}
  if (value.split.length > 0) {
    resp = {
      err: false,
      msg: '',
      value
    }
  } else {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

const Required = (value) => {
  let resp = { err: false, value }
  if (value === '') {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const validators = (type, value) => {
  let resp = { err: false, value }
  switch (type) {
    case 'cpf':
      value = value.replace(/[^0-9]/g, '')
      resp = Cpf(value)
      break
    case 'required':
      resp = Required(value)
      break
    case 'number':
      value = value.replace(/[^0-9]/g, '')
      resp = Number(value)
      break
    case 'phone':
      value = value.replace(/[^0-9]/g, '')
      resp = Tel(value)
      break
    default:
      break
  }
  return resp
}
