import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import * as data from './data'
import * as styles from './styles'

import Alert from '../../components/Alert'
import Table from '../../components/Table'
import HTable from '../../components/HeaderTable'
import FormUser from '../../components/FormUser'
import * as Icon from '../../components/icon'
import api from '../../services/api'

import { routes } from '../../routes/data'
import { validators } from '../../utils/validators'

const User = () => {
  const navigate = useNavigate()
  const markerRef = React.useRef(null)

  const [open, setOpen] = React.useState(false)
  const [openMap, setOpenMap] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [teste, setTeste] = React.useState(false)
  const [autocompleteValue, setAutocompleteValue] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')
  const [msg, setMsg] = React.useState('')

  const center = [-16.072870129464917, -57.68599658169415]
  const [position, setPosition] = React.useState(center)

  const [menu, setMenu] = React.useState('')
  const [userData, setUserData] = React.useState([])
  const [getPlan, setGetPlan] = React.useState([])
  const [dataVehicle, setDataVehicle] = React.useState([])
  const [formData, setFormData] = React.useState(data.defaultValuedataForm)
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [uuid, setUuid] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue
    })
  }

  const eventHandlers = React.useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker != null) {
          setPosition([marker.getLatLng().lat, marker.getLatLng().lng])
        }
      }
    }),
    []
  )

  const validateForm = (form, value) => {
    let temp = false
    for (let i = 0; i < form.length; i++) {
      temp = validators(form[i].type, value[form[i].value]).err
      if (temp === true) break
    }
    return temp
  }

  const handleSubmit = async (type) => {
    setIsSubmit(true)
    setError(false)
    setMsg('')
    if (validateForm(data.dataForm, formData) === false) {
      setLoading(true)
      setOpen(true)

      const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

      if (!authData) {
        return
      }

      const config = {
        headers: {
          Authentication: authData.token
        }
      }

      let sendData = {}

      let role = await (await api.get('/role/all/', config)).data
      role = role.filter(element => element.name === 'Usuario')[0]
      if (role === null) {
        return
      }

      if (type === 'new') {
        sendData = {
          login_info: {
            email: formData.email,
            password: formData.password,
            role_uuid: role.uuid
          },
          user_info: {
            name: formData.name,
            cpf: formData.cpf,
            document: formData.document,
            phone_number: formData.phone_number,
            district: formData.district,
            street: formData.street,
            number: formData.number,
            cep: formData.cep,
            complement: formData.complement
          }
        }

        api.post('/user/', sendData, config).then(() => {
          setLoading(false)
        }).catch((err) => {
          if (err?.response?.data?.detail[0]?.msg) {
            setMsg(err.response.data.detail[0].msg)           
          }
          setError(true)
          setLoading(false)
        })
      } else if (type === 'edit') {
        const aux = getPlan.filter(element => element.name === inputValue)[0]

        if (aux) {
          sendData = {
            name: formData.name,
            cpf: formData.cpf,
            document: formData.document,
            phone_number: formData.phone_number,
            district: formData.district,
            street: formData.street,
            number: formData.number,
            cep: formData.cep,
            complement: formData.complement,
            latitude: formData.latitude,
            longitude: formData.longitude,
            plan_uuid: aux.uuid
          }
        } else {
          sendData = {
            name: formData.name,
            cpf: formData.cpf,
            document: formData.document,
            phone_number: formData.phone_number,
            district: formData.district,
            street: formData.street,
            number: formData.number,
            cep: formData.cep,
            complement: formData.complement,
            latitude: formData.latitude,
            longitude: formData.longitude
          }
        }

        api.put(`/user/${formData.uuid}`, sendData, config).then(() => {
          setLoading(false)
        }).catch((err) => {
          if (err?.response?.data?.detail[0]?.msg) {
            setMsg(err.response.data.detail[0].msg)           
          }
          setError(true)
          setLoading(false)
        })
      }
    }
  }

  const handleDelete = () => {
    setIsDelete(false)
    setError(false)
    setLoading(true)

    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))
    if (authData) {
      const config = {
        headers: {
          Authentication: authData?.token
        }
      }

      api.delete(`/user/${uuid}`, config).then(() => {
        setLoading(false)
      }).catch(() => {
        setError(true)
        setLoading(false)
      })
    }
  }

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))
    if (authData) {
      const config = {
        headers: {
          Authentication: authData.token
        }
      }

      api.get('/user/all/', config).then((resp) => {
        setUserData(resp.data)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      navigate(routes.login)
    }
  }, [navigate])

  React.useEffect(() => {
    if (formData.cep && formData.cep.length === 8 && menu !== 'show' && teste === false) {
      setTeste(true)
      api.get(`https://viacep.com.br/ws/${formData.cep}/json/`).then((resp) => {
        if (!resp.data?.erro) {
          setFormData({
            ...formData,
            district: resp.data.bairro,
            street: resp.data.logradouro,
            complement: resp.data.complemento
          })
        }
      })
    } else {
      if (formData.cep.length < 8) {
        setTeste(false)
      }
    }
  }, [formData])

  React.useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))
    if (authData) {
      const config = {
        headers: {
          Authentication: authData.token
        }
      }

      api.get('/plan/all/', config).then((resp) => {
        const aux = []
        if (resp.data) {
          setGetPlan(resp.data)
          for (let i = 0; i < resp.data.length; i += 1) {
            aux.push(resp.data[i].name)
          }

          setDataVehicle(aux.sort((a, b) => {
            if (a < b) { return -1 }
            if (a > b) { return 1 }
            return 0
          }))
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [])

  return (
    <Container sx={styles.Container}>
      { menu === 'new'
        ? <FormUser
            data={data.dataForm}
            value={formData}
            isSubmit={isSubmit}
            onChange={handleChangeForm}
            handleSubmit={() => handleSubmit('new')}
            loading={loading}
            text='Cadastro de Cliente'
            type='form'
            cancel={() => setMenu('')}
          />
        : menu === 'edit'
          ? <FormUser
              data={data.dataFormEdit}
              value={formData}
              isSubmit={isSubmit}
              onChange={handleChangeForm}
              handleSubmit={() => handleSubmit('edit')}
              loading={loading}
              text='Editor de Cadastro de Cliente'
              type='edit'
              cancel={() => setMenu('')}
              autocompleteValue={autocompleteValue}
              autocomplete={setAutocompleteValue}
              inputValue={inputValue}
              input={setInputValue}
              autocompleteData={dataVehicle}
              open={() => {
                setOpenMap(true)
                if (formData.latitude && formData.longitude) {
                  setPosition([formData.latitude, formData.longitude])
                }
              }}
            />
          : menu === 'show'
            ? <FormUser
                data={data.dataFormShow}
                value={formData}
                text='Cliente'
                type='perfil'
                cancel={() => setMenu('')}
              />
            : <>
          <HTable text="Clientes" onClick={() => {
            setFormData(data.defaultValuedataForm)
            setMenu('new')
          }} />
          <CssBaseline />
          <Table
            rows={userData}
            columns={data.columns}
            show={(id) => {
              let userShow = userData.filter(element => element.uuid === id)[0]
              const plan = getPlan.filter(element => element.uuid === userShow.plan_uuid)[0]

              if (plan) {
                userShow = {
                  ...userShow,
                  plan_uuid: plan.name
                }
              }

              setFormData(userShow)
              setMenu('show')
            }}
            edit={(id) => {
              const userEdit = userData.filter(element => element.uuid === id)[0]
              setFormData(userEdit)
              const plan = getPlan.filter(element => element.uuid === userEdit.plan_uuid)[0]
              if (plan) {
                setAutocompleteValue(plan.name)
                setInputValue(plan.name)
              } else {
                setAutocompleteValue('')
                setInputValue('')
              }
              setMenu('edit')
            }}
            delete={(id) => {
              setIsDelete(true)
              setOpen(true)
              setUuid(id)
            }}
          />
        </>
       }

        <Alert
          open={open}
          loading={loading}
          error={error}
          msg={msg}
          handleModalClose={() => setOpen(false)}
          handleClose={() => document.location.reload(true)}
          delete={isDelete}
          handleDelete={handleDelete}
        />

        <Dialog open={openMap}>
          <Box sx={styles.BoxMap}>
            <MapContainer
              center={center}
              zoom={14}
              zoomControl={true}
              style={{ width: '100%', height: '100%' }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                position={position}
                ref={markerRef}
                icon={Icon.blackIcon}
              />
            </MapContainer>
            <Stack direction='row-reverse' spacing={5} sx={{ margin: '10px' }}>
              <Button
                variant='contained'
                sx={styles.Button}
                onClick={() => {
                  setFormData({
                    ...formData,
                    latitude: position[0],
                    longitude: position[1]
                  })
                  setOpenMap(false)
                }}
              >Confirmar</Button>
              <Button
                variant='contained'
                sx={styles.Button}
                onClick={() => setOpenMap(false)}
              >Voltar</Button>
            </Stack>
          </Box>
        </Dialog>
      </Container>
  )
}

export default User
