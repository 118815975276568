import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import React from 'react'
import PropTypes from 'prop-types'

import { validators } from '../../utils/validators'
import { mask } from '../../utils/mask'

const CustomTextField = (props) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <>
      {
        props.label.toLowerCase() === 'password'
          ? <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              variant="standard"
              label={props.label}
              error={props.isSubmit && validators(props.type, props.value).err}
              helperText={props.isSubmit && validators(props.type, props.value).msg}
              value={mask(props.mask, props.value)}
              onChange={(e) => props.onChange(
                props.data,
                validators(props.type, e.target.value).value
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          : <TextField
              fullWidth
              type='text'
              variant="standard"
              label={props.label}
              error={props.isSubmit && validators(props.type, props.value).err}
              helperText={props.isSubmit && validators(props.type, props.value).msg}
              value={mask(props.mask, props.value)}
              onChange={(e) => {
                props.onChange(
                  props.data,
                  validators(props.type, e.target.value).value
                )
              }}
            />
      }
    </>
  )
}

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  mask: PropTypes.string,
  isSubmit: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired
}

CustomTextField.defaultProps = {
  mask: ''
}

export default CustomTextField
