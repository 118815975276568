import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import Alert from '../../components/Alert'
import { routes as apiRoutes } from '../../routes/data'
import api from '../../services/api'

import * as styles from './styles'

const Calls = () => {
  const navigate = useNavigate()

  const [calls, setCalls] = React.useState([])
  const [users, setUsers] = React.useState([])
  const [vigilants, setVigilants] = React.useState([])

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const loadCalls = async () => {
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(apiRoutes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    await api.get('/user/all/', config).then((resp) => {
      setUsers(resp.data)
    }).catch((err) => {
      console.log(err)
    })

    await api.get('/vigilant/all/', config).then((resp) => {
      setVigilants(resp.data)
    }).catch((err) => {
      console.log(err)
    })

    await api.get('/call/all/', config).then((resp) => {
      const tempCalls = []

      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i].status !== 'Chamada cancelada' && resp.data[i].status !== 'Chamada concluida') {
          tempCalls.push(resp.data[i])
        }
      }
    
      setCalls(tempCalls)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSubmit = async (uuid) => {
    setLoading(true)
    const authData = JSON.parse(localStorage.getItem('@OlhoVivo/painel'))

    if (!authData) {
      navigate(apiRoutes.login)
    }

    const config = {
      headers: {
        Authentication: authData.token
      }
    }

    const sendData = {
      user_concluded: true,
      vigilant_concluded: true
    }

    await api.put(`/call/${uuid}`, sendData, config)
      .then(() => {
        setLoading(false)
        setOpen(false)
        loadCalls()
      })
      .catch(() => {
        setLoading(false)
        setOpen(false)
      }) 
  }

  React.useEffect(() => {
    loadCalls()

    const timer = setInterval(() => loadCalls(), 10000)
    return () => clearInterval(timer)
  }, [])

  return (
    <Container sx={styles.Container}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Stack direction="row" spacing={15}>
          <FormControlLabel
          control={
            <Checkbox
            checked={checkedRoutes}
            onChange={() => setCheckedRoutes(!checkedRoutes)}
            />
          }
          label="Rotas"
          />
        </Stack> */}
        <Typography variant="h5" sx={styles.Text}>Chamadas</Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Vigilante</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Finalizar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calls.map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell align="left">
                    {
                      users.find(element => element.uuid === row.user_uuid)?.name ||
                      '----------'
                    }
                  </TableCell>
                  <TableCell align="right">
                    {row.user_concluded === true ?
                      <CheckCircleIcon sx={{ fontSize: 30 }} color="success" /> : 
                      <CancelIcon sx={{ fontSize: 30 }} color="error" />}
                  </TableCell>
                  <TableCell align="right">
                    {
                      vigilants.find(element => element.uuid === row.vigilant_uuid)?.name ||
                      '----------'
                    }
                  </TableCell>
                  <TableCell align="right">
                    {row.vigilant_concluded === true ?
                      <CheckCircleIcon sx={{ fontSize: 30 }} color="success" /> : 
                      <CancelIcon sx={{ fontSize: 30 }} color="error" />}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      type="submit"
                      variant="contained"
                      onClick={()=> handleSubmit(row.uuid)}
                    >
                      Finalizar
                    </Button>
                  </TableCell>  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <br />
        
        {calls.length === 0 &&
          <Typography variant="h6" sx={styles.Text}>
            Não existe chamados em andamento
          </Typography>
        }
      </Box>

      <Alert
          open={open}
          loading={loading}
          error={error}
          msg={msg}
          handleModalClose={() => setOpen(false)}
          handleClose={() => document.location.reload(true)}
          delete={false}
          handleDelete={() => {}}
        />
    </Container>
  )
}

export default Calls
