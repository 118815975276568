import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import CancelIcon from '@mui/icons-material/Cancel'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import Logo from '../../assets/logo3.png'
import LogoRisc from '../../assets/LogoRISC.png'
import Form from '../../components/Form'
import api from '../../services/api'
import { routes } from '../../routes/data'
import { validators } from '../../utils/validators'

import * as data from './data'
import * as styles from './styles'

const Login = () => {
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(data.defaultValuedataForm)

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const navigate = useNavigate()
  const matches = useMediaQuery('(min-width:600px)')

  const handleChangeForm = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue
    })
  }

  const validateForm = (form, value) => {
    let temp = false
    for (let i = 0; i < form.length; i++) {
      temp = validators(form[i].type, value[form[i].value]).err
      if (temp === true) break
    }
    return temp
  }

  const handleSubmit = () => {
    setLoading(false)
    setIsSubmit(true)
    setOpen(true)

    if (validateForm(data.dataForm, formData) === false) {
      setLoading(true)
      api.post('/login/admin/', formData).then((resp) => {
        setLoading(false)
        setFormData(resp.data)
        localStorage.setItem('@OlhoVivo/painel', JSON.stringify(resp.data))
        navigate(routes.user)
      }).catch((err) => {
        console.log(err)
        if (err?.response?.data?.detail[0]?.msg) {
          if (err.response.data.detail[0].msg === 'value is not a valid email address') {
            setMsg('Formato de Email Invalido')
          } else {
            setMsg(err.response.data.detail[0].msg)
          }
        } else {
          setMsg(err?.response?.data?.detail[0]?.msg || 'ERRO! Verifique a Internet e Tente Novamante.')
        }
        setError(true)
        setLoading(false)
      })
    }
  }

  return (
    <Container maxWidth={false} sx={styles.Container}>
      <CssBaseline />
      <Box sx={matches ? styles.BoxL : styles.None}>
        <Box component="img" alt="logo Risc" src={LogoRisc} sx={styles.Img} />
        <Box component="img" alt="logo App" src={Logo} sx={styles.Logo} />
      </Box>
      <Box sx={matches ? styles.BoxR : styles.BoxRNone}>
        {!matches && <Box component="img" alt="logo App" src={Logo} sx={styles.Logo} />}
        <Box sx={{
          width: matches ? '50%' : '100%',
          borderWidth: '1px'
        }}>
          <Typography variant="h3" gutterBottom>
            Login
          </Typography>

          <Typography variant="h6" sx={{ color: 'text.secondary', mb: 5 }}>
            Insira seus dados Abaixo.
          </Typography>

          <Form
            data={data.dataForm}
            value={formData}
            onChange={handleChangeForm}
            isSubmit={isSubmit}
          />

          <Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              { loading ? <CircularProgress /> : 'Login' }
            </Button>
          </Box>
        </Box>
        {!matches && <Box component="img" alt="logo Risc" src={LogoRisc} sx={styles.ImgRR} />}

        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          { loading
            ? <Box sx={styles.Dialog}>
                <TimelapseIcon sx={{ fontSize: 100 }} />
                <Typography variant="h4">Aguarde</Typography>
              </Box>
            : error
              ? <Box sx={styles.Dialog}>
                <CancelIcon sx={{ fontSize: 100 }} color="error" />
                <Typography variant="h5">{msg}</Typography>
                <Button
                  onClick={() => setOpen(false)}
                  className="animate__animated animate__fadeIn"
                  size="large"
                  variant="contained"
                >
                  Confirmar
                </Button>
              </Box>
              : <></>
          }
        </Dialog>
      </Box>
    </Container>
  )
}

export default Login
