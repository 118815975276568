import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import * as styles from './styles'

import Logo from '../../assets/logo3.png'

const Menu = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [expanded, setExpanded] = React.useState(props.accordion[0]?.id || '')
  const [isActived, setIsActived] = React.useState('Início')

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleActived = (value, link) => {
    setIsActived(value)
    navigate(link)
  }

  return (
    <React.Fragment>
      <Drawer
        sx={styles.Drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        <styles.DrawerHeader>
          <Box component="img" alt="logo App" src={Logo} sx={styles.Logo} />
          <IconButton onClick={props.onClick}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </styles.DrawerHeader>
        <Divider />
        {props.accordion.map((element) => (
          <styles.Accordion
            key={element.id}
            expanded={expanded === element.id}
            onChange={handleChange(element.id)}
          >
            <styles.AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <ListItemIcon>
                {element.icon}
              </ListItemIcon>
              <Typography>{element.text}</Typography>
            </styles.AccordionSummary>
            <styles.AccordionDetails>
                {element.data.map((element) => (
                  <ListItem
                    key={element.text}
                    sx={isActived === element.text ? styles.ActivedButton : {}}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => handleActived(element.text, element.link)}
                    >
                      <ListItemIcon>
                        {element.icon}
                      </ListItemIcon>
                      <ListItemText primary={element.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </styles.AccordionDetails>
          </styles.Accordion>
        ))}
        <Divider />
        {props.list.map((element) => (
          <ListItem
            key={element.id}
            sx={isActived === element.text ? styles.ActivedButton : {}}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleActived(element.text, element.link)}
            >
              <ListItemIcon>
                {element.icon}
              </ListItemIcon>
              <ListItemText primary={element.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </Drawer>
    </React.Fragment>
  )
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  accordion: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired
}

export default Menu
