import Grid from '@mui/material/Grid'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

import TextField from '../TextField'

const Form = (props) => {
  return (
    <Grid container spacing={2} sx={styles.Box}>
      {props.data.map((element) => (
        <Grid key={element.id} item xs={element.xs} md={element.md}>
          <TextField
            label={element.label}
            type={element.type}
            data={element.value}
            mask={element?.mask}
            isSubmit={props.isSubmit}
            value={props.value[element.value]}
            onChange={props.onChange}
          />
        </Grid>
      ))}
    </Grid>
  ) 
}

Form.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Form
